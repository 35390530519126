import { HttpHandler, HttpRequest, HttpInterceptor, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

    constructor(private router: Router, private authService: AuthService, private inject: Injector) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let bearer = this.authService.getBearerToken()
        if(bearer && !req.urlWithParams.includes('/login') && !req.urlWithParams.includes('cloudinary')
        && !req.urlWithParams.includes('datos.gov')){
            req = req.clone({
                setHeaders: {
                  Authorization: `Bearer ${bearer}`
                }
              });
        }

        return next.handle(req)
        .pipe(
            filter((event: HttpEvent<any>) => (event instanceof HttpResponse)),
            tap((stateEvent: HttpEvent<any>) => {
              const authService = this.inject.get(AuthService);
              if (sessionStorage.getItem('token') && !authService.isAuthenticated()) {
                authService.logout()
              }
            },
              (error) => {
                const authService = this.inject.get(AuthService);
                if (sessionStorage.getItem('token') && !authService.isAuthenticated()) {
                    authService.logout()
                } else {
                  if (error.status === 403 ) {
                    authService.logout()
                  }
                }
              })
          );
    }
}