import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { BehaviorSubject, filter, Observable } from "rxjs";
import { Company } from "src/app/common/models/company.model";
import { User } from "src/app/common/models/user.model";
import { UserSession } from "src/app/common/models/userSession.model";
import { AuthApiService } from "./api/auth.api.service";
import { NotificationService } from "./notification.service";
import { Module } from "src/app/common/models/module.model";

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private currentUserSubject = new BehaviorSubject<User | null>(null);
    currentUser$ = this.currentUserSubject.asObservable().pipe(filter(user => user != null));

    private currentUserCompanySubject = new BehaviorSubject<Company | null>(null);
    currentUserCompany$ = this.currentUserCompanySubject.asObservable().pipe(filter(company => company != null));

    constructor(private router: Router, private authApiService: AuthApiService,
        private jwtHelper: JwtHelperService, private notificationService: NotificationService) { }

    isAuthenticated(): boolean {
        const token = sessionStorage.getItem('token');
        if (token && this.jwtHelper.isTokenExpired(token)) {
            sessionStorage.removeItem('token');
        }
        return token ? !this.jwtHelper.isTokenExpired(token) : false;
    }

    getBearerToken(): string | null {
        return sessionStorage.getItem('token')
    }

    login(username: string, password: string): Observable<UserSession> {
        return this.authApiService.login(username, password)
    }

    setSessionToken(token: string) {
        sessionStorage.setItem('token', token);
    }

    setUserSession(userSession: User) {
        sessionStorage.setItem('user', JSON.stringify(userSession));
        this.currentUserSubject.next(userSession);
    }

    setModules(moduleList: Module[]){
        let user:User = this.getUserSession()
        user.modules = moduleList
        this.setUserSession(user)
    }

    getUserSession(): User {
        let userSession = sessionStorage.getItem('user')
        return userSession ? JSON.parse(userSession) : undefined;
    }

    getCurrentCompany(): Company {
        let userSession = this.getUserSession()
        return userSession.company
    }

    setCompanySession(company: Company): void {
        let userSession = this.getUserSession();
        userSession.company = company;
        sessionStorage.setItem('user', JSON.stringify(userSession));
        this.currentUserCompanySubject.next(company);
    }

    logout(logoutMessage?: string) {
        sessionStorage.clear()
        localStorage.clear()
        this.notificationService.createGeneral({ show: true, type: 'sucess', message: 'Has cerrado correctamente la sesión' })
        this.router.navigate(['/login/']);
    }

    register(user: User, enterpriseRegister: boolean): Observable<UserSession> {
        return this.authApiService.register(user, enterpriseRegister);
    }

}