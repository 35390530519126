import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './shared/services/guards/auth-guard.service';
import { ModuleSelectorComponent } from './core/components/module-selector/module-selector.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./features/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuardService],
    canLoad: [AuthGuardService]
  },
  {
    path: 'concent',
    loadChildren: () => import('./features/concent-external/concent-external.module').then(m => m.ConcentExternalModule )
  },

  { path: 'modulos', component: ModuleSelectorComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
