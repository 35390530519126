import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Capacity } from "src/app/common/models/capacity.model";
import { Module } from "src/app/common/models/module.model";
import { Rol } from "src/app/common/models/rol.model";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ModuleApiService {

    constructor(private httpClient: HttpClient) { }

    getAll(): Observable<Module[]>{
        return this.httpClient.get<Module[]>(`${environment.apiUrl}modules`, { responseType: 'json' }); 
    }

    saveModulesForUser(userId: number, modules:Module[]): Observable<Module[]> {
        return this.httpClient.post<Module[]>(`${environment.apiUrl}modules/users/${userId}`, modules , { responseType: 'json' }); 
    }

    getModulesForUser(userId: number, returnRole: boolean): Observable<Module[]>{
        let params = new HttpParams();
        params = params.append('returnRole', returnRole);
        
        return this.httpClient.get<Module[]>(`${environment.apiUrl}modules/users/${userId}`, { params: params,responseType: 'json' }); 
    }

    saveRol( rol: Rol): Observable<Rol>{
        return this.httpClient.post<Rol>(`${environment.apiUrl}roles`,rol, { responseType: 'json' }); 
    }
    
    getAllRolesByModule(idCompany: number, returnRole: boolean): Observable<Module[]>{
        let params = new HttpParams();
        params = params.append('returnRole', returnRole ? true: false);
        return this.httpClient.get<Module[]>(`${environment.apiUrl}modules/${idCompany}`, {responseType: 'json' , params: params}); 
    }

    getRoleByModuleIdAndCompany(idCompany: number,idModule: number, returnRole: boolean): Observable<Module>{
        let params = new HttpParams();
        params = params.append('returnRole', returnRole ? true: false);
        return this.httpClient.get<Module>(`${environment.apiUrl}modules/${idModule}/${idCompany}`, {params: params,responseType: 'json' });
    }

    searchRolesByModule(idCompany: number,idModule: number, name: string, status: string): Observable<Rol[]>{
        let params = new HttpParams();
            params = params.append('name', name? name : '');
            params = params.append('status', status? status: '');
        
        return this.httpClient.get<Rol[]>(`${environment.apiUrl}roles/${idCompany}/${idModule}/search`, {params: params, responseType: 'json' }); 
    }

    deleteRolesByModule(idCompany: number,idModule: number, idRoles:number[], forceDeletion: boolean): Observable<Rol[]>{
        let params = new HttpParams();
        params = params.append('force', forceDeletion? true: false);

        return this.httpClient.post<Rol[]>(`${environment.apiUrl}roles/${idCompany}/${idModule}/delete`,
        idRoles.length > 0 ? idRoles:[idRoles],{ params: params,responseType: 'json' })

    }

    getCapacities(idModule: number): Observable<Capacity[]>{
        return this.httpClient.get<Capacity[]>(`${environment.apiUrl}modules/${idModule}/capacities`, {responseType: 'json' }); 
    }

    searchCapacities(idModule: number, nameCapacity: string): Observable<Capacity[]>{
        let params = new HttpParams();
        params = params.append('name', nameCapacity);

        return this.httpClient.get<Capacity[]>(`${environment.apiUrl}modules/${idModule}/capacities/search`, {params: params, responseType: 'json' }); 
    }
}