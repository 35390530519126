import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Module } from "src/app/common/models/module.model";
import { ModuleApiService } from "./api/module.api.service";
import { Rol } from "src/app/common/models/rol.model";
import { Capacity } from "src/app/common/models/capacity.model";

@Injectable({providedIn: 'root'})
export class ModuleService {

    private editedModuleSubject = new BehaviorSubject<boolean | null>(null); 
    userEditedModules$ = this.editedModuleSubject.asObservable()

    constructor(private moduleApiService: ModuleApiService){}

    getAllModules(): Observable<Module[]>{
        return this.moduleApiService.getAll()
    }

    saveModulesForUser(userId: number, modules:Module[]) : Observable <Module[]> {
        return this.moduleApiService.saveModulesForUser(userId, modules)
    }

    getModulesForUser(userId: number, returnRole: boolean): Observable<Module[]>{
        return this.moduleApiService.getModulesForUser(userId,returnRole)
    }

    setUserModulesSession(modules: boolean) {
        this.editedModuleSubject.next(modules);
    }

    addRol( rol: Rol): Observable<Rol>{
        return this.moduleApiService.saveRol(rol)
    }

    getAllRolesByModule(idCompany: number,returnRole: boolean): Observable<Module[]>{
        return this.moduleApiService.getAllRolesByModule(idCompany, returnRole)
    }

    getRoleByModuleIdAndCompany(idCompany: number,idModule: number, returnRole: boolean): Observable<Module>{
        return this.moduleApiService.getRoleByModuleIdAndCompany(idCompany,idModule, returnRole)
    }

    searchRolByModule(idCompany: number,idModule: number, name: string, status: string): Observable<Rol[]>{
        return this.moduleApiService.searchRolesByModule(idCompany,idModule, name, status)
    }

    deleteRolByModule(idCompany: number,idModule: number, idRoles:number[], forceDeletion: boolean): Observable<Rol[]>{
        return this.moduleApiService.deleteRolesByModule(idCompany,idModule, idRoles, forceDeletion)
    }

    getCapacities(idModule: number): Observable<Capacity[]>{
        return this.moduleApiService.getCapacities(idModule)
    }

    searchCapacities(idModule: number, nameCapacity: string): Observable<Capacity[]>{
        return this.moduleApiService.searchCapacities(idModule,nameCapacity)
    }
}