import { Injectable } from "@angular/core";
import { NbToastrService } from "@nebular/theme";
import { Subject } from "rxjs";
import { Notification } from "src/app/common/models/notification.model";

@Injectable({providedIn: 'root'})
export class NotificationService {

  private generalNotificationSubject = new Subject<Notification>();
  generalNotification$ = this.generalNotificationSubject.asObservable();
  private index: number = 0;

  constructor(private toastrService: NbToastrService ) {
  }

  createGeneral(notificationObject: Notification){
    this.generalNotificationSubject.next(notificationObject);
  }

  createSpecific(notificationObject: Notification){
      this.toastrService.show(notificationObject.message,notificationObject.translate,
        {duration: notificationObject.duration, status:notificationObject.type} );
      
  }
}
