<div class="row justify-content-around mt-3 grid-container">
    <div class="center-both">
        <nb-card class="hcc-semi-round custom-modal">
            <nb-card-body>
                <span>
                    <h1 class="my-text">Selecciona los módulos que usarás en el software</h1>
                </span>
                <div class="module-grid">
                    <ng-container *ngFor="let mod of modules; let i = index">
                        <div class="image-item">
                            <nb-checkbox [(ngModel)]="mod.active" class="checkbox" (click)="selectModule(mod)" *ngIf="mod.active" status="info"></nb-checkbox>
                            <img [src]="mod.icon" alt="Modulo {{ mod.name }}" class="module-image" [ngStyle]="{'filter': mod.active ? 'none' : 'grayscale(100%)'}" 
                                (click)="selectModule(mod)" />
                            <p>{{ mod.name }}</p>
                        </div>
                    </ng-container>
                </div>
            </nb-card-body>
            <nb-card-footer class="hcc-center">
                <button nbButton [nbSpinner]="requesting" nbSpinnerStatus="info" size="medium" fullWidth
                    shape="semi-round" status="info" class="label-saparated hcc-pointer"
                    (click)="finishModuleSelection()">
                    {{ requesting ? '' : 'Activar' }}
                </button>
            </nb-card-footer>
        </nb-card>
    </div>
</div>