import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs/internal/Observable";
import { environment } from "src/environments/environment";
import { UserSession } from "src/app/common/models/userSession.model";
import { User } from "src/app/common/models/user.model";

@Injectable({
    providedIn: 'root'
})
export class AuthApiService {

    constructor(private httpClient: HttpClient) { }

    login(username: string, password: string): Observable<UserSession> {
        const body = {
            email: username,
            password: password
        };
        return this.httpClient.post<UserSession>(`${environment.apiUrl}users/login`, body, { responseType: 'json' });
    }

    register(user: User, enterpriseRegister: boolean): Observable<UserSession>{
        let params = new HttpParams();
        params = params.append('companyRegister', enterpriseRegister);
        params = params.append('sendEmail', true)

        
        return this.httpClient.post<UserSession>(`${environment.apiUrl}users/register`, user, { params: params, responseType: 'json' });
    }

}
