// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://back-end-hcc.herokuapp.com/api/',
  //apiUrl: 'http://localhost:8087/api/',
  colDataUrl: 'https://www.datos.gov.co/resource/xdk5-pm3f.json',
  cloudinarySetting: {
    cloudName: 'dgqp4oje0',//TODO: change cloudinary id
    apiUrl: 'https://api.cloudinary.com/v1_1/',
    apiKey: '873558349218922',
    uploadPreset: 'jvyttmkg'
  },
  geoSetting:{
    apiUrl: 'https://us1.locationiq.com/v1/reverse.php',
    key: 'pk.4862f902ed1beba73589f609d7dd9eb0',
    mapboxUrl: 'https://api.mapbox.com/geocoding/v5/mapbox.places/',
    mapboxKey: 'pk.eyJ1IjoiaHVkMDEiLCJhIjoiY2xnZWo4bG40MDUwZDNncDVnejNuN3B1aCJ9.lUNgfcUYoh6276XcWVXwDQ',
    googleMapsUrl: 'https://maps.googleapis.com/maps/api/geocode/json?latlng=',
    googleMapsKey: 'USE_TOKEN_HERE',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
