import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Module } from "src/app/common/models/module.model";
import { User } from "src/app/common/models/user.model";
import { AuthService } from "src/app/shared/services/auth.service";
import { ModuleService } from "src/app/shared/services/module.service";
import { NotificationService } from "src/app/shared/services/notification.service";

@Component({
    selector: 'hcc-module-selector',
    templateUrl: './module-selector.component.html',
    styleUrls: ['./module-selector.component.scss']
})
export class ModuleSelectorComponent implements OnInit {

    selectionModules: boolean = true
    @Output() modulesAttached = new EventEmitter<boolean>();
    
    modules: Module[] = [
    ];
       
    requesting: boolean

    constructor(private moduleService: ModuleService, private notificationService: NotificationService, private authService: AuthService) { }

    ngOnInit() {
        this.listAllModules()
    }

    listAllModules() {
        this.moduleService.getAllModules().subscribe(modules => {
            this.modules = modules.map(module => ({ ...module, active: true }));
        }, error => {
            this.notificationService.createSpecific({ message: 'Hubo un error recuperando los módulos', translate: 'Advertencia', type: 'warning', duration: 5000 });
        })
    }

    selectModule(module: Module){
        module.active = !module.active
    }

    finishModuleSelection(){
        let user = this.authService.getUserSession()
        let modulesToSave = this.modules.filter(module => module.active)
        if(user && user.id && modulesToSave && modulesToSave.length > 0){
            this.moduleService.saveModulesForUser(user.id, modulesToSave).subscribe(
                modules => {
                    this.requesting = false
                    this.authService.setModules(modules)
                    this.modulesAttached.emit(modules && modules.length > 0)
                    this.notificationService.createSpecific({ message: `Gracias por seleccionar los módulos`, translate: 'Éxitoso', type: 'success', duration: 5000 });
                },
                error =>{
                    this.requesting = false
                    this.notificationService.createSpecific({ message: 'Hubo un error sincronizando los módulos', translate: 'Advertencia', type: 'warning', duration: 5000 });
                }
            )
        }else{
            this.notificationService.createSpecific({ message: 'Selecciona al menos un módulo para empezar', translate: 'Advertencia', type: 'warning', duration: 5000 });
        }
    }

}